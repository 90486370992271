<template>
<base-modal title="Upload Logo">
  <image-manager ref="logoManager" @upload="uploadLogo"/>
</base-modal>  
</template>

<script>
export default {

  props: {
    companyId: Number
  },

  methods: {
    uploadLogo(blob) {
      this.uploading = true
      this.$store.dispatch('site/uploadLogo', blob).then(() => {
        this.uploading = false
        this.$refs.logoManager.completed()
        this.$toast.success('Image uploaded')
        this.$close(false)
      }).catch(() => {
        this.uploading = false
        this.$toast.error('whoops something went wrong, please try again.')
      })
    },
  }

}
</script>
