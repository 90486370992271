<template>
<base-modal title="Upload Image">
  <image-manager ref="profilePicManager" @upload="uploadProfilePicture"/>
</base-modal>  
</template>

<script>
export default {

  props: {
    userId: Number
  },

  methods: {
    uploadProfilePicture(blob) {
      this.uploading = true
      this.$store.dispatch('user/uploadProfilePic', { file: blob, user: this.userId }).then(() => {
        this.uploading = false
        this.$refs.profilePicManager.completed()
        this.$toast.success('Image uploaded')
        this.$close(false)
      }).catch(() => {
        this.uploading = false
        this.$toast.error('whoops something went wrong, please try again.')
      })
    },
  }

}
</script>
