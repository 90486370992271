<template>
  <base-modal form :title="`New ${Naming.AssetGroup}`">
    <div class="grid gap-1">
      <text-input
        classes="is-medium"
        :error="$root.errors.description"
        :value="assetGroup.description"
        @input="description">
        Description
      </text-input>

      <data-selector
        :error="$root.errors.risk_id"
        :value="assetGroup.risk_id"
        @input="risk"
        :items="riskLevelList"
        value-key="id"
        label-key="level"
        :description="`Select the risk level for ${Naming.Zones} of this type.`">
        Risk
      </data-selector>

      <data-selector
        multiple
        searchable
        tag-class="is-info"
        delete-buttons
        :error="$root.errors.asset_types"
        :value="selectedAssetTypes"
        :items="assetTypeList"
        label-key="name"
        value-key="id"
        :description="`Select which ${Naming.AssetTypes} are allowed in ${Naming.Zones} of this type.`"
        @input="zoneAssetTypes">
        Allowed {{ Naming.AssetTypes }}
        <template #item="{ item }">
          <div>
            <p class="has-text-weight-bold">{{ item.name }}</p>
            <p class="has-text-weight-medium is-size-7 has-text-grey">Risk: {{ item.risk.level }} • Short Code: {{ item.short_code || '–' }}</p>
          </div>
        </template>
      </data-selector>
    </div>

    <template #footer>
      <submit-button :working="working" @submit="store" class="is-success is-rounded" left-icon="check">Create {{ Naming.AssetGroup }}</submit-button>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    selectedAssetTypes: [],
  }),

  beforeDestroy() {
    this.clearType()
  },

  created() {
    this.$store.dispatch('riskLevel/loadRiskLevelList')
    this.$store.dispatch('assetType/loadAssetTypeList')
  },

  methods: {
    ...mapMutations('assetGroup', [
      'description',
      'risk',
      'clearType',
      'zoneAssetTypes'
    ]),
    store() {
      this.working = true
      this.$store.dispatch('assetGroup/store').then(() => {
        this.working = false
        this.$close(false)
        this.$toast.success('Saved')
      }).catch(error => {
        this.working = false
        this.$toast.error('Oops something went wrong')
      })
    }
  },

  computed: {
    ...mapGetters('assetGroup', [
      'assetGroup'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ])
  }
}
</script>
