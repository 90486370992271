<template>
  <base-modal title="Upload Logo">
    <image-manager ref="logoManager" @upload="uploadLogo"/>
  </base-modal>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    companyId: Number,
  },

  methods: {
    uploadLogo(blob) {
      this.uploading = true
      this.$store.dispatch('client/uploadLogo', blob).then(() => {
        this.uploading = false
        this.$refs.logoManager.completed()
        this.$toast.success('Image uploaded')
        this.$close(false)
      }).catch(error => {
        this.uploading = false
        this.$refs.logoManager.removeImage()
        this.$close(false)
        if (error.response.status === 422) {
          let errorMessage = error.response.data.errors.file[0]
          this.$toast.error(errorMessage)
        } else {
          this.$toast.error('whoops something went wrong, please try again.')
        }
      })
    },
  },

}
</script>
