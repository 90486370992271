<template>
  <base-modal form :title="`Sign Off ${Naming.Job} ${job.number}`">
    <loader v-bind="{ loading }">
      <!-- Job Details -->
      <div class="grid has-1-columns gap-1">
        <div>
          <!-- technician name -->
          <div class="pb-1">
            <strong>{{ job.technician.full_name }}</strong> is requesting a sign off.
          </div>

          <!-- Start and End Dates -->
          <div class="pb-1 has-bottom-border-cool-blue"
               v-if="job.start_date && !job.completed_at && !job.start_date_is_future">
            This {{ Naming.Job.toLowerCase() }} was started on {{ job.start_date | date('L') }} and has been running for a total of
            <strong>{{ totalRuntime(job.total_minutes) }}.</strong>
            <span v-if="job.pauses.length">The {{ Naming.Job.toLowerCase() }} has been paused for a total of {{
                totalRuntime(totalPauseTime)
              }}</span>
          </div>
        </div>

        <!-- Sign Off -->
        <div class="grid has-1-columns gap-1 align-items-center">

          <!-- Signer Signature -->
          <p>Please Sign Below (using your mouse)</p>
          <VueSignaturePad ref="signaturePad"
                           class="has-border-thin-grey"/>

          <!-- Signer Name -->
          <text-input
              class="is-1-columns"
              v-model="name"
              data-tooltip="Name and Surname"
              :placeholder="'Name and Surname'">Signer Name
          </text-input>
        </div>
      </div>

      <footer class="buttons is-right mt-2">
        <!-- Sign Off Button -->
        <action-button
            @click="save"
            :working="working"
            left-icon="check"
            class="is-rounded is-success has-text-weight-bold mr-1"
            v-if="canSignOff">
          Sign Off
        </action-button>
        <!-- Clear Signature Button -->
        <action-button
            @click="undo"
            left-icon="trash"
            class="is-rounded is-danger">
          Undo Signature
        </action-button>
      </footer>
    </loader>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {VueSignaturePad} from "vue-signature-pad"
import Vue from 'vue'
import convertBase64ToImageFile from "@/utils/convertBase64ToImageFile"

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = 1440
const HOURS_IN_DAY = 24

Vue.use(VueSignaturePad)
export default {
  components: {
    VueSignaturePad
  },

  data: () => ({
    working: false,
    opening: false,
    loading: false,
    name: '',
  }),

  methods: {

    save() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature()

      if (isEmpty) {
        this.$toast.error("Please sign the Job Card")
        return
      }
      const signature = convertBase64ToImageFile(data);
      const file = new File([signature], 'signature.png', {type: 'image/png'})

      this.working = true
      this.$store.dispatch('job/signJob', {
        jobId: this.job.id,
        signatureName: this.name,
        signatureImage: file
      }).then(() => {
        this.$toast.success(this.Convert(this.$lang.job.signedAndClosed))
        this.working = false
        this.$close(true)
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    undo() {
      this.$refs.signaturePad.clearSignature()
    },

    totalRuntime(minutes) {
      if (minutes < MINUTES_IN_HOUR) {
        return minutes + ' minutes'
      }
      if (minutes < MINUTES_IN_DAY) {
        return (minutes / MINUTES_IN_HOUR).toFixed(0) + ' hours'
      }

      if (minutes > MINUTES_IN_DAY) {
        let days = (minutes / (MINUTES_IN_HOUR * HOURS_IN_DAY)).toFixed(0)
        if (days === '1') {
          return days + ' day'
        }
        return days + ' days'
      }
    },
  },

  computed: {
    ...mapGetters('job', [
      'job'
    ]),
    canSignOff() {
      return this.name !== ''
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearAllStores()
    next()
  }
}
</script>
