<template>
  <base-modal class="warning" v-bind="{ title }">
    <article class="content" v-html="message"/>
    <div slot="footer" class="buttons">
      <action-button :class="['is-rounded has-tooltip-multiline', cancelClass]" @click="$close(0)" :data-tooltip="cancelTooltip">{{ cancelText }}</action-button>
      <action-button :class="['has-text-weight-bold is-rounded has-tooltip-multiline', confirmClass]" @click="$close(1)" :data-tooltip="comfirmTooltip">{{ confirmText }}</action-button>
      <action-button v-if="confirm2Text" :class="['has-text-weight-bold is-rounded has-tooltip-multiline', confirm2Class]" @click="$close(2)" :data-tooltip="comfirm2Tooltip">{{ confirm2Text }}</action-button>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    confirm2Text: {
      type: String,
      default: null
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmClass: {
      type: String|Object,
      default: 'is-success'
    },
    confirm2Class: {
      type: String|Object,
      default: 'is-success'
    },
    cancelClass: {
      type: String|Object,
      default: 'is-light'
    },
    comfirmTooltip: {
      type: String,
      default: null,
    },
    comfirm2Tooltip: {
      type: String,
      default: null,
    },
    cancelTooltip: {
      type: String,
      default: null,
    }
  }
}
</script>
