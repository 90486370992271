<template>
<base-modal>
  <img :src="imageUrl">
</base-modal>  
</template>

<script>
export default {
  props: {
    imageUrl: String
  }
}
</script>
