<template>
<base-modal wide :title="`Edit ${Naming.JobType.toLowerCase()}`">
  <form>
    <div class="columns is-multiline">
      <div class="column is-6">
        <text-input 
          required 
          :error="$root.errors.description" 
          :value="jobType.description" 
          @input="description">
          Description
        </text-input>
      </div>
      <div class="column is-6">
        <number-input
          placeholder="ex: Repair, Survey, etc…"
          :precision="0"
          :min="50"
          :max="100"
          required
          :error="$root.errors.pass_percentage"
          :value="jobType.pass_percentage"
          @input="passPercentage">
          Pass Percentage
        </number-input>
      </div>
      <div class="column is-6">
        <switch-input
          :description="`${Naming.JobTypes} that are not active will not be available on ${Naming.Jobs.toLowerCase()}`"
          classes="is-medium"
          @input="toggleActive"
          :value="jobType.active">
          Active {{ Naming.JobType }}
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
          :description="`When required, ${Naming.Jobs.toLowerCase()} of this type will require the customer to provide their name as well as a signature before closing it off.`"
          classes="is-medium"
          @input="toggleSignatureRequirement"
          :value="jobType.requires_signature">
          Requires a Signature
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
          :description="`${Naming.Jobs} which carry this ${Naming.JobType.toLowerCase()} will be limited to 1 ${Naming.Inspection.toLowerCase()} per ${Naming.Job.toLowerCase()}.`"
          classes="is-medium"
          @input="toggleSingleInspection"
          :value="jobType.single_inspection">
          Limit {{ Naming.Jobs }} to 1 {{ Naming.Inspection.toLowerCase() }} per {{ Naming.Job.toLowerCase() }}.
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
          :description="`Display map (if available) on the ${Naming.Inspection.toLowerCase()} report PDF.`"
          classes="is-medium"
          @input="toggleMapOnReport"
          :value="jobType.report_with_map">
          {{ Naming.Inspection }} Report - Show Map
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
            :description="`Display ${Naming.CustomFiled.toLowerCase()} meta data for the ${Naming.Asset.toLowerCase()} on the PDF ${Naming.Inspection.toLowerCase()} report.`"
            classes="is-medium"
            @input="toggleAssetMetaFieldsOnInspectionReport"
            :value="jobType.report_with_asset_meta">
          {{ Naming.Inspection }} Report - Show {{ Naming.Asset }} {{ Naming.CustomFields }}
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
            :description="`Display ${Naming.CustomFiled.toLowerCase()}  meta data for the ${Naming.Asset.toLowerCase()} on the PDF ${Naming.Job.toLowerCase()} report.`"
            classes="is-medium"
            @input="toggleAssetMetaFieldsOnJobReport"
            :value="jobType.job_report_with_asset_meta">
          {{ Naming.Job }} Report - Show {{ Naming.Asset }} {{ Naming.CustomFields }}
        </switch-input>
      </div>
      <div class="column is-6">
        <switch-input
            :description="`When required, ${Naming.Jobs.toLowerCase()} of this type will require the ${Naming.Technician.toLowerCase()} to sign for the ${Naming.Job.toLowerCase()} and ${Naming.Inspections.toLowerCase()} at ${Naming.Job.toLowerCase()} closure.`"
            classes="is-medium"
            @input="toggleTechnicianSignatureRequirement"
            :value="jobType.requires_technician_signature">
          Requires {{ Naming.Technician }} Signature
        </switch-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button :working="working" @submit="save" class="is-success" left-icon="check">Save</submit-button>
      </div>
      <div class="column has-text-right is-narrow">
        <action-button :disabled="type.jobs_count > 0" :working="working" @click="deleteType" class="is-danger" left-icon="trash">Delete</action-button>
        <p class="help is-danger" v-if="type.jobs_count > 0">
          This {{ Naming.JobType }} has {{ Naming.Jobs }} assigned.
        </p>
      </div>
    </div>
  </form>
</base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  props: {
    type: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    working: false
  }),

  created() {
    this.$store.commit('jobType/setJobType', this.type)
  },

  beforeDestroy() {
    this.clearJobType()
  },

  methods: {
    ...mapMutations('jobType', [
      'description',
      'clearJobType',
      'passPercentage',
      'toggleActive',
      'toggleSignatureRequirement',
      'toggleSingleInspection',
      'toggleMapOnReport',
      'toggleAssetMetaFieldsOnInspectionReport',
      'toggleAssetMetaFieldsOnJobReport',
      'toggleTechnicianSignatureRequirement',
    ]),
    save() {
      this.working = true
      this.$store.dispatch('jobType/save').then(() => {
        this.$toast.success(this.Convert(this.$lang.jobType.saved))
        this.working = false
        this.$close(false)
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    async deleteType() {
      if (await this.$confirm({
        ...this.$lang.jobType.confirmDelete,
        confirmClass: 'is-danger'
      })) {
        this.$store.dispatch('jobType/delete').then(() => {
          this.working = false
          this.$close(true)
          this.$toast.success(this.Convert(this.$lang.jobType.deleted))
        }).catch(() => {
          this.working = false
          this.$whoops()
        })
      }
    }
  },

  computed: {
    ...mapGetters('jobType', [
      'jobType'
    ])
  }
}
</script>
