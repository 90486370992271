<template>
  <base-modal wide :title="`${Naming.Company} Branding`" icon="brush">
    <loader v-bind="{ loading }" :text="`Checking your ${Naming.Company} branding status`">
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': component === 'app-logo' }">
            <a @click="component = 'app-logo'">App Logo</a>
          </li>
          <li :class="{ 'is-active': component === 'email-logo' }">
            <a @click="component = 'email-logo'">Email Logo</a>
          </li>
        </ul>
      </div>
      <component class="company-branding-editor" :is="component"/>
    </loader>
  </base-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import AppLogo from '@/views/company/branding/AppLogo'
import EmailLogo from '@/views/company/branding/EmailLogo'

export default {

  components: {
    AppLogo,
    EmailLogo,
  },

  data: () => ({
    loading: true,
    component: 'app-logo'
  }),

  computed: {
    ...mapState('company', ['branding']),
  },

  mounted() {
    this.loadCompanyBranding().finally(() => {
      this.loading = false
    }).catch(() => {
      this.$toast.error('Branding could not be fetched. Please try again or contact support.')
    })
  },

  destroyed() {
    this.clearBranding()
  },

  methods: {
    ...mapMutations('company', [
      'clearBranding'
    ]),
    ...mapActions('company', [
      'loadCompanyBranding',
    ])
  }

}
</script>
