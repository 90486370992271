<template>
<base-modal title="Edit Answer Comment">
  <message class="is-danger">
    Please note that all changes are tracked for data integrity.
  </message>

  <div class="columns">
    <div class="column is-6">
      <p class="is-flex justify-between">
        <strong>Answer</strong>
        <span v-if="answer.answer === 1" class="is-flex justify-between align-center">
          <icon class="has-text-success" icon="check-circle lg"/>
          <span>Passed</span>
        </span>
        <span v-if="answer.answer === 0" class="is-flex justify-between align-center">
          <icon class="has-text-danger" icon="times-circle lg"/>
          <span>Failed</span>
        </span>
        <span v-if="answer.answer === -1" class="is-flex justify-between align-center">
          <icon class="has-text-warning" icon="dot-circle lg"/>
          <span>N/A</span>
        </span>
      </p>
      <p class="is-flex justify-between">
        <strong>{{ Naming.Check }}</strong> {{ answer.check.name }}
      </p>
    </div>
  </div>
  <div class="columns">
    <div class="column">
      <text-area v-model="comment">Comment</text-area>
    </div>
  </div>

  <template #footer>
    <div class="buttons">
      <action-button :working="saving" @click="saveComment">Save</action-button>
    </div>
  </template>
</base-modal>  
</template>
<script>
import { post } from '@/api/request'

export default {
  
  props: {
    answer: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    saving: false,
    comment: ''
  }),

  created() {
    if(this.answer.comment) {
      this.comment = this.answer.comment.text
    }
  },

  methods: {
    saveComment() {
      this.saving = true 
      post(`api/inspection/${this.answer.inspection_id}/answer/${this.answer.id}/comment`, {
        comment: this.comment
      }, ({data}) => {
        this.$toast.success('Comment Updated')
        this.$close(true)
        this.saving = false
      }, error => this.saving = false)
    }
  }

}
</script>