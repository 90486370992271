<template>
  <base-modal div wide title="Predefined Values Editor" :close-button="false" :overlay-closes="false">
    <div class="grid has-2-columns gap-1">
      <p>Predefined Values</p>
    </div>
    <div>
      <table class="table is-fullwidth">
        <thead>
        <tr>
          <th class="has-text-left">Value</th>
          <th class="has-text-right"></th>
          <th class="has-text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(option, index) in options" :key="index">
          <td class="has-text-left"><span :class="{'has-text-weight-bold': option.default}">{{ option.value }}</span>
          </td>
          <td class="has-text-right is-narrow is-centered">
            <action-button
                v-if="option.default !== true"
                @click="setAsDefaultValue(index)"
                class="is-rounded is-success mr-1 is-inverted is-thin">
              Set As Default
            </action-button>
            <action-button
                v-if="option.default === true"
                @click="removeDefault()"
                class="is-rounded is-success mr-1 is-inverted is-thin">
              Remove As Default
            </action-button>
          </td>
          <td class="has-text-right is-narrow">
            <action-button
                @click="removeValue(index)"
                class="is-rounded is-danger is-inverted is-thin">
              Remove
            </action-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="columns">
      <div class="column" v-on:keyup.enter="addValue">
        <text-input v-model="option.value">
          Add New Value
        </text-input>
      </div>
      <div class="column is-flex align-end is-narrow mr-1">
        <action-button
            @click="addValue"
            :working="working"
            class="is-rounded is-success">
          <icon icon="plus"/>
        </action-button>
      </div>
    </div>


    <footer class="buttons is-right mt-2 mr-1">

      <action-button
          @click="savePredefinedValues"
          :working="working"
          left-icon="save"
          class="is-rounded is-success">
        Save Predefined Values
      </action-button>
      <action-button
          @click="shouldClose"
          :working="working"
          left-icon="times"
          class="is-rounded is-danger">
        Close
      </action-button>
    </footer>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {findIndex} from "lodash";

export default {

  data: () => ({
    working: false,
    options: [],
    option: {
      value: '',
      default: false,
    },
    saved: false,
  }),

  mounted() {
    this.setOptions()
  },

  methods: {
    savePredefinedValues() {
      const customFieldId = this.assetType?.customFieldId
      this.$store.commit('assetType/setPredefinedValue', {id: customFieldId, options: this.options})
      this.saved = true
      this.shouldClose()
    },
    addValue() {
      if (this.option.value?.length === 0 || this.option.value === '') {
        return
      }

      if (this.optionsHasOption(this.option)) {
        this.$toast.warning('You have already added this option')
        return
      }

      this.options.push(this.option)
      this.option = {
        value: '',
        default: false,
      }
    },
    removeValue(index) {
      this.options.splice(index, 1)
    },
    setAsDefaultValue(index) {
      this.removeDefault()
      this.options[index].default = true
    },
    removeDefault() {
      this.options.forEach(option => option.default = false)
    },
    optionsHasOption(option) {
      return this.options.find(item => item.value.toLowerCase() === option.value.toLowerCase())
    },
    setOptions() {
      const index = findIndex(this.assetType.custom_fields, field => field.field_id === this.assetType?.customFieldId)
      let field = this.assetType?.custom_fields[index]
      this.options = Array.from(field?.options)
    },
    async confirmLeave() {
      return this.$confirm({
        title: "Warning",
        message: `Leave without saving?`,
        confirmText: "Close",
        cancelText: "Continue",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => {
            this.$whoops()
          })
    },
    async shouldClose() {
      if (!this.saved) {
        if (await this.confirmLeave()) {
          this.$close()
        }
      } else {
        this.$close()
      }
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
  },
}
</script>
