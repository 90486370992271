<template>
  <base-modal v-bind="{ title }">
    <article class="content" v-html="message"/>
    <div slot="footer" class="buttons">
      <action-button class="button is-primary is-rounded" @click="$close(true)">Close</action-button>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
  }
}
</script>
