<template>
<base-modal :title="`Impersonate ${Naming.User}`">
  <loader 
    v-bind="{ loading }">

    <div style="min-height: 500px;">
      Please note that all impresonations are recorded.

      <server-data-selector
        searchable
        :items="users"
        v-model="user"
        value-key="id"
        label-key="friendly_name"
        :on-type="search">
        Select {{ Naming.User }}
      </server-data-selector>
    </div>

  </loader>
  <template v-if="!loading" #footer>
    <div class="buttons">
      <submit-button 
        :working="working"
        @submit="signInAsUser">Impersonate {{ Naming.User }}</submit-button>
    </div>
  </template>
</base-modal>  
</template>
<script>
import { services } from '@/mixins/js-helpers'
import { impersonateUser, searchUsers } from '@/api/support'
import { debounce } from 'lodash'

export default {


  data: () => ({
    loading: false,
    working: false,
    users: [],
    user: ''
  }),

  beforeCreate() {},

  methods: {
    signInAsUser() {
      this.working = true
      impersonateUser(this.user, ({data}) => {
        services.auth.setToken(data)
        location.reload()
      }, error => {
        this.working = false
      })
    },

    search(text) {
      if(!text.length) {
        // do nothing if there's nothing to search for
        return
      }
      if (text.length > 3) {
        this.debouncedSearch(text)
      }
    },

    debouncedSearch: debounce(function(search) {
      searchUsers({search}, ({data}) => {
        this.users = data.map((user) => {
          return {
            id: user.id,
            friendly_name: `${user.id} - ${user.full_name} - ${user.email}`
          }
        })
      }, error => {})
    }, 300),
  }

}
</script>