var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-modal',{attrs:{"wide":"","title":`${_vm.Naming.Inspection} Results`}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.Naming.Check))]),_c('th',[_vm._v("Date/Time")]),_c('th',{staticClass:"has-text-right"},[_vm._v("Outcome")])])]),_c('tbody',_vm._l((_vm.inspection.answers),function(answer){return _c('tr',{key:answer.id},[_c('td',[_vm._v(" "+_vm._s(answer.check.name)+" "),(answer.check.instant_fail && answer.answer === 0)?_c('span',{staticClass:"tag is-danger"},[_vm._v("instant fail")]):_vm._e()]),_c('td',[_vm._v(_vm._s(answer.created_at))]),_c('td',{staticClass:"is-narrow"},[_c('yes-no',{attrs:{"value":_vm.getOutcome(answer),"yes-text":"Passed","no-text":"Failed"}})],1)])}),0)]),(!_vm.inspection.answers.length)?_c('p',{staticClass:"has-text-centered"},[_vm._v("No Failed "+_vm._s(_vm.Naming.Checks))]):_vm._e()])]),_c('p',{staticClass:"mt-1 mb-1"},[_c('strong',[_vm._v("Notes and Additional Comments")])]),_vm._l((_vm.inspection.comments),function(comment){return _c('div',{key:comment.id,staticClass:"is-flex align-center"},[_c('avatar',{staticClass:"mr-1 mb-1",attrs:{"path":comment.user.profile.picture_url,"name":comment.user.full_name}}),_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(comment.user.full_name))]),_c('p',[_vm._v(_vm._s(comment.text))])])],1)}),_c('div',{staticClass:"is-flex justify-between",attrs:{"slot":"footer"},slot:"footer"},[_c('action-button',{staticClass:"is-cool-blue",attrs:{"working":_vm.downloadingInspection,"left-icon":"download"},on:{"click":function($event){return _vm.downloadInspectionReport(_vm.inspection)}}},[_vm._v(" Download Report ")]),_c('router-link',{staticClass:"button is-primary",attrs:{"to":{
        name: 'inspection-evidence',
        params: {
          inspection: _vm.inspection.id,
          site: _vm.job.site_id,
          zone: _vm.inspection.asset.zone_id,
          asset: _vm.inspection.asset_id
        }
      }},nativeOn:{"click":function($event){return _vm.$close(false)}}},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.Naming.Inspection)+" Overview")]),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }