<template>
  <base-modal :title="`${Naming.Zone} Transfer: ${zone.name}`">
    <div class="columns">
      <div class="column">
        <data-selector v-model="client_id" :items="clientsAvailable" value-key="id" label-key="legal_name">
          Select a {{ Naming.Client.toLowerCase() }} to Transfer this {{ Naming.Zone.toLowerCase() }} to.
        </data-selector>

        <!-- TODO: Display correct error message when there are no sites in the selected client. -->
        <div v-if="selectedClient !== null && sitesAvailable.length === 0">
          <p class="has-text-danger">There are no zones for {{ activeSite.name }}.</p>
          <router-link @click.native="$close($false)" class="button is-small is-info mt-1"
                       :to="{ name: 'zone-create', params: { site: activeSite.id }}">
            <span>Add {{ Naming.Zone }}</span>
            <icon icon="arrow-right"/>
          </router-link>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <data-selector :disabled="selectedClient === null || sitesAvailable.length === 0" v-model="site_id"
                       :items="sitesAvailable" value-key="id"
                       label-key="name">
          Select a {{ Naming.Site.toLowerCase() }} to Transfer this {{ Naming.Zone }} to.
        </data-selector>
      </div>
    </div>

    <div slot="footer">
      <submit-button :working="working" class="is-warning is-medium" @submit="actionTransfer">Transfer {{ Naming.Zone }}</submit-button>
    </div>
  </base-modal>
</template>

<script>
import { filter } from 'lodash'
import { mapGetters } from 'vuex'

export default {

  props: {
    zone: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      client_id: '',
      site_id: '',
      working: false
    }
  },

  created() {
    this.$store.dispatch('client/loadClientList')
    this.$store.dispatch('site/loadSiteList')
  },

  methods: {
    actionTransfer() {
      this.working = true
      this.$store.dispatch('zone/transferZone', { 
        zone_id: this.zone.id,
        to_site_id: this.site_id
      }).then(() => {
        this.$toast.success('Zone Transferred')
        this.working = false
        this.$router.push({
          name: 'site-zones',
          params: {
            site: this.site_id,
          }
        })
        this.$close(false)
      }).catch(error => {
        this.working = false
      })
    }
  },

  computed: {
    clientsAvailable() {
      return this.clientList
    },
    selectedClient() {
      return filter(this.clientsAvailable, client => +client.id === +this.client_id)[0] || null
    },

    sitesAvailable() {
      if (this.selectedClient === null) {
        return []
      }

      return filter(this.siteList, site => +site.client_id === +this.client_id && +site.id !== +this.zone.site_id)
    },
    selectedSite() {
      return filter(this.sitesAvailable, site => +site.id === +this.site_id)[0] || null
    },

    ...mapGetters('client', [
      'clientList'
    ]),
    ...mapGetters('site', [
      'siteList'
    ])
  }

}
</script>
