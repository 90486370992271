<template>
  <base-modal wide form :title="`Edit ${Naming.Check}`">
    <message v-if="hasLockedChecklist" class="is-danger" title="Locked">
      This {{ Naming.Check.toLowerCase() }} is associated with a locked {{ Naming.Checklist.toLowerCase() }}, as such it cannot be edited.
      Please contact support if you would like to make changes to this {{ Naming.Check.toLowerCase() }}.
    </message>
    <div class="columns is-multiline">
      <div class="column is-half">
        <text-input
          required
          :disabled="hasLockedChecklist"
          class="has-tooltip-bottom"
          :description="`Give this ${Naming.Check.toLowerCase()} a descriptive label.`"
          :error="$root.errors.name"
          :value="check.name"
          @input="name">
          Name
        </text-input>
        <p v-if="!hasValidNameLength" class="has-text-danger is-danger">A valid Name must be provided. Names have a maximum length of 255 characters.</p>
      </div>
      <div class="column is-half">
        <switch-input
            :description="`If this ${Naming.Check.toLowerCase()} fails, the ${Naming.Inspection.toLowerCase()} will fail, putting the ${Naming.Asset.toLowerCase()} into a state of risk.`"
            classes="is-medium"
            @input="toggleInstantFail"
            :disabled="hasLockedChecklist"
            :value="check.instant_fail">
          Instant Fail
        </switch-input>
      </div>
      <div class="column is-half">
        <data-selector
          :error="$root.errors.image_rule"
          :value="check.image_rule"
          :disabled="hasLockedChecklist"
          @input="imageRule"
          :items="checkRules"
          :description="`Adjust the ruleset governing photographic evidence for this ${Naming.Check.toLowerCase()}.`">
          Image Rule
        </data-selector>
      </div>
      <div class="column is-half">
        <switch-input
            :description="`This will allow the ${Naming.Check.toLowerCase()} to be skipped by selecting the N/A button in the app.`"
            classes="is-medium"
            @input="toggleCanSkip"
            :disabled="hasLockedChecklist"
            :value="check.can_skip">
          Can Skip
        </switch-input>
      </div>
      <div class="column is-half">
        <div>
          <h2 class="label is-marginless">
            {{ Naming.Check }} Image
          </h2>
          <div class="card" v-if="imagePreview">
            <small class="has-text-grey mb-1">The maximum image size is 5 MB.</small>
            <div class="card-content p-0 pt-1">
              <div class="image" style="!important; max-width: 60%;">
                <figure class="image is-6by5">
                  <img :src=imagePreview :alt="`${Naming.Check} Image`">
                </figure>
              </div>
            </div>
          </div>
          <small class="has-text-grey" v-else>Upload an image of the {{ Naming.Asset.toLowerCase() }}. The maximum image size is 5 MB.</small>
          <div class="is-flex justify-between mt-1">
            <input type="file"
                   :disabled="hasLockedChecklist"
              @change="filesChange"
              accept="image/*"
              class="is-primary is-small is-rounded">
            <action-button
              v-if="check.image"
              @click="removeImage"
              left-icon="trash"
              class="is-danger is-small is-rounded"
              :working="false"
              :disabled="hasLockedChecklist"
            >
              Remove Image
            </action-button>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <data-selector
          :error="$root.errors.comment_rule"
          :value="check.comment_rule"
          @input="commentRule"
          :disabled="hasLockedChecklist"
          :items="checkRules"
          :description="`Adjust the ruleset governing comments for this ${Naming.Check.toLowerCase()}.`">
          Comment Rule
        </data-selector>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half"></div>
      <div class="column is-half">
        <text-area
          required
          description="Provide an accurate description of the task to be performed."
          :disabled="hasLockedChecklist"
          :error="$root.errors.task"
          :value="check.task"
          @input="task">
          Task Description
        </text-area>
        <p class="help-danger" v-if="$root.errors.delete">{{ $root.errors.delete[0] }}</p>
        <p v-if="!hasValidTaskLength" class="has-text-danger is-danger">A valid Task must be provided. Tasks have a maximum length of 750 characters.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <predefined-comments-table
          v-if="$root.hasAbility('manage-predefined-comments', 'Billow\\Models\\Checklist\\Check')"
          @error="handleError"
          :can-edit="!hasLockedChecklist"/>
      </div>
    </div>
    <div slot="footer" class="buttons">
      <submit-button
        :working="working"
        :disabled="hasLockedChecklist || error || !canUpdateCheck"
        @submit="update"
        class="is-success is-inverted"
        left-icon="check">
        Save {{ Naming.Check }}
      </submit-button>
      <action-button
        :disabled="hasLockedChecklist"
        :working="working"
        @click="deleteCheck"
        class="is-danger is-inverted tooltip"
        left-icon="trash">
        Delete {{ Naming.Check }}
      </action-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

const MAX_NAME_LENGTH = 255
const MAX_TASK_LENGTH = 750
export default {

  data: () => ({
    working: false,
    loadingCheck: true,
    imagePreview: null,
    removingImage: false,
    changingImage: false,
    disabled: null,
    error: false,
  }),

  created() {
    this.load()
  },

  beforeDestroy() {
    this.clearCheck()
  },

  methods: {
    ...mapMutations('check', [
      'name',
      'task',
      'setCheck',
      'clearCheck',
      'imageRule',
      'commentRule',
      'image',
    ]),
    ...mapMutations('checkPredefinedComments', [
      'destroyAllCheckPredefinedComments',
    ]),
    removeImage() {
      this.removingImage = true
      delete this.check.image
      this.imagePreview = null
    },
    filesChange(event) {
      if (event.target.files.length === 0) {
        this.changingImage = false
        this.removingImage = false
        return;
      }

      this.changingImage = true
      this.check.image = event.target.files[0]
      this.imagePreview = URL.createObjectURL(this.check.image)
      this.removingImage = false
    },
    load() {
      this.$store.dispatch('check/loadCheck').finally(() => {
        this.loadingCheck = false
        if (this.check.image) {
          this.imagePreview = this.check.image
        }
      })
    },
    update() {
      this.working = true
      this.check.removeImage = +this.removingImage
      if (this.changingImage === false) {
        delete this.check.image
      }
      Promise.all([
        this.$store.dispatch('check/update'),
        this.$store.dispatch('checkPredefinedComments/store'),
        this.$store.dispatch('checkPredefinedComments/update'),
        this.$store.dispatch('checkPredefinedComments/destroy'),
      ]).then(() => {
        this.$toast.success(this.Convert(this.$lang.check.saved))
        this.working = false
        this.$close(true)
      }).catch(error => {
        this.working = false

        if (error.response.status === 422) {
          this.$toast.error(Object.values(error.response.data.errors)[0][0])
          return
        }

        this.$whoops()
      })
    },
    async deleteCheck() {
      if (await this.$confirm({
        title: this.Convert('Delete Check'),
        message: this.Convert('Are you sure you want to delete this check?'),
      })) {
        this.working = true
        this.destroyAllCheckPredefinedComments()
        Promise.all([
          this.$store.dispatch('check/delete'),
          this.$store.dispatch('checkPredefinedComments/destroy'),
        ]).then(() => {
          this.$toast.success(this.Convert(this.$lang.check.deleted))
          this.working = false
          this.$close(true)
        }).catch(error => {
          this.working = false
          this.$whoops()
        })
      }
    },
    toggleInstantFail(){
      this.check.instant_fail = !this.check.instant_fail
    },
    toggleCanSkip(){
      this.check.can_skip = !this.check.can_skip
    },
    /**
     *
     * @param {string} message
     */
    handleError(message) {
      if (message) {
        this.error = true
      } else {
        this.error = false
      }
    }
  },

  computed: {
    ...mapGetters('check', [
      'check',
    ]),
    ...mapGetters('list', [
      'checkRules',
    ]),
    hasLockedChecklist() {
      return this.check.locked_checklists_count > 0;
    },
    canUpdateCheck() {
      return this.hasValidNameLength && this.hasValidTaskLength
    },
    hasValidNameLength() {
      return this.check.name.length <= MAX_NAME_LENGTH
    },
    hasValidTaskLength() {
      return this.check.task.length <= MAX_TASK_LENGTH
    },
  },
  
}
</script>
