<template>
  <base-modal form :title="`${Naming.Job} Summary Report Options`">
    <div class="grid has-2-columns gap-1">
      <p>Please select from the options below.</p>
    </div>
    <div class="grid has-3-columns gap-1 mt-1">
      <switch-input
          classes="is-medium"
          @input="toggleAssetMetaData"
          :value="options.show_asset_metadata"
      >
        Include Asset Metadata
      </switch-input>
      <switch-input
          classes="is-medium"
          @input="toggleJobSignatures"
          :value="options.show_job_signatures"
      >
        Include {{ Naming.Job }} Signatures
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleShowPassFailOrPercentage"
          :value="options.show_pass_or_percentage"
      >
        {{ jobPassFailOrPercentage }}
      </switch-input>
    </div>

    <div class="grid has-3-columns gap-1">
      <switch-input
          classes="is-medium"
          @input="toggleClientAddress"
          :value="options.show_client_address"
      >
        Include {{ Naming.Client }} Address
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleSiteAddress"
          :value="options.show_site_address"
      >
        Include {{ Naming.Site }} Address
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleShowCompanyLogos"
          :value="options.show_company_logo"
      >
        Show {{ Naming.Company }} Logos
      </switch-input>

    </div>
    <div class="grid has-1-columns gap-1 pt-1">

      <h3 class="has-text-primary">Show {{ Naming.Inspections }} for {{ Naming.AssetTypes }}</h3>
      <div v-if="showSelectAllAssetTypes">
        <switch-input
            classes="is-medium"
            @input="toggleAllAssetTypes"
            :value="showAllAssetTypes"
        >
          Include All {{ Naming.AssetTypes }}
        </switch-input>
      </div>
    </div>

    <div class="grid has-1-columns gap-1 pt-1">
      <data-selector
          multiple
          searchable
          delete-buttons
          tag-class="is-info"
          :value="options.asset_types"
          :items="assetTypes"
          label-key="name"
          value-key="id"
          @input="updateAssetTypes"
      >{{ Naming.AssetTypes }}
      </data-selector
      >
    </div>

    <div class="grid has-2-columns gap-1 pt-1">
      <switch-input
          v-if="showAnswersToggle"
          classes="is-medium"
          @input="toggleShowAnswers"
          :value="options.show_answers"
      >
        Show Answers for {{ Naming.Inspections }}
      </switch-input>

      <switch-input
          v-if="showAnswerCommentsToggle"
          classes="is-medium"
          @input="toggleShowAnswerComments"
          :value="options.show_answer_comments"
      >
        Show Answer Comments for {{ Naming.Inspections }}
      </switch-input>
    </div>

    <footer class="buttons is-right mt-2">

      <action-button
          @click="downloadJobSummaryReport"
          :working="working"
          left-icon="download"
          class="is-rounded is-success">
        Download
      </action-button>
    </footer>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {job as backend} from "@/api";
import download from "downloadjs";

export default {

  data: () => ({
    working: false,
    options: {
      show_asset_metadata: false,
      show_job_signatures: false,
      show_client_address: false,
      show_site_address: false,
      show_pass_or_percentage: true,
      show_company_logo: false,
      asset_types: [],
      show_answers: false,
      show_answer_comments: false,
    },
    showAllAssetTypes: false,
    assetTypes: [],
    thresholdToShowAddAllAssetTypesToggle: 3,

  }),

  mounted() {
    this.getAssetTypesByJobId()
  },

  methods: {

    toggleAllAssetTypes() {
      this.showAllAssetTypes = !this.showAllAssetTypes

      if (this.showAllAssetTypes) {
        this.options.asset_types = this.assetTypes.map(assetType => ({id: assetType.id, name: assetType.name}))
        return
      }
      this.options.asset_types = [];
    },

    toggleAssetMetaData() {
      this.options.show_asset_metadata = !this.options.show_asset_metadata
    },

    toggleShowAnswers() {
      this.options.show_answers = !this.options.show_answers
    },

    toggleShowAnswerComments() {
      this.options.show_answer_comments = !this.options.show_answer_comments
    },

    toggleJobSignatures() {
      this.options.show_job_signatures = !this.options.show_job_signatures
    },

    toggleClientAddress() {
      this.options.show_client_address = !this.options.show_client_address
    },

    toggleSiteAddress() {
      this.options.show_site_address = !this.options.show_site_address
    },

    toggleShowPassFailOrPercentage() {
      this.options.show_pass_or_percentage = !this.options.show_pass_or_percentage
    },

    toggleShowCompanyLogos() {
      this.options.show_company_logo = !this.options.show_company_logo
    },

    updateAssetTypes(payload) {
      this.options.asset_types = payload
      this.shouldToggleAnswerTogglesToFalse()
      this.setShowAllAssetTypes()
    },

    shouldToggleAnswerTogglesToFalse() {
      if (this.options.asset_types.length === 0) {

        this.options.show_answer_comments = false
        this.options.show_answers = false
      }
    },

    setShowAllAssetTypes() {
      if (this.options.asset_types.length < this.assetTypes.length) {

        this.showAllAssetTypes = false

        this.shouldToggleAnswerTogglesToFalse()
      }
    },

    downloadJobSummaryReport() {
      const payload = {
        jobId: this.job.id,
        options: this.options
      }

      this.working = true

      backend.downloadSummaryReport(payload, ({data}) => {
        download(data, `${this.Convert('job')}-summary-report-#${this.job.number}-${this.job.site.name}.pdf`, 'application/pdf')
      }, error => {
        this.$whoops()
      }).finally(() => this.working = false)
    },

    getAssetTypesByJobId() {
      backend.getAssetTypesByJob(this.job.id, (data) => {
        this.assetTypes = data
      }, error => this.$whoops())
    },
  },

  computed: {
    ...mapGetters('job', [
      'job'
    ]),
    ...mapGetters('inspection', [
      'inspections'
    ]),

    showSelectAllAssetTypes() {
      return this.assetTypes.length > this.thresholdToShowAddAllAssetTypesToggle
    },

    showAnswersToggle() {
      return this.options.asset_types?.length > 0
    },

    showAnswerCommentsToggle() {
      return this.options.show_answers && this.options.asset_types?.length
    },

    jobPassFailOrPercentage() {
      return this.options.show_pass_or_percentage ? "Show Job Pass/Fail" : "Show Job Percentage"
    },
  },
}
</script>
