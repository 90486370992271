<template>
  <base-modal :title="`Assign ${Naming.Asset}: ${asset.code}`">
    <div class="columns">
      <div class="column is-12">
        <message class="is-primary is-flex justify-between">
          This {{ Naming.Asset }} will be transferred to its parent {{ Naming.Asset }}'s {{ Naming.Zone }}. Only parent {{ Naming.Asset }} {{Naming.Zones}} where the <strong>{{ asset.type.name }}</strong> {{ Naming.AssetType }} is available will be shown.
          <icon 
            icon="question-circle" 
            class="has-tooltip-multiline has-tooltip-bottom has-text-grey-lighter"
            :data-tooltip="`The parent  ${Naming.Asset}'s ${Naming.Zone} must have ${Naming.AssetTypes} set up that correspond with this ${Naming.Asset}'s ${Naming.AssetTypes}. Only ${Naming.Zone}s that are configured correctly and have the ${ asset.type.name } ${Naming.AssetTypes} will be shown in the Parent ${Naming.Asset} ${Naming.Zone} dropdown menu.`"/>
        </message>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <data-selector
          v-model="zoneId"
          :items="zoneList"
          @input="switchZones"
          value-key="id"
          label-key="name">
          Parent {{Naming.Asset}} {{Naming.Zone}}
        </data-selector>
      </div>
    </div>
    <div class="columns">
      <div class="column large-bottom-margin">
        <data-selector
          v-if="showAssets"
          v-model="parentAssetId"
          :items="sortedFilteredAssets(selectedZone)"
          value-key="id"
          label-key="code">
          Assign to Parent {{Naming.Asset}}
        </data-selector>
      </div>
    </div>

    <div slot="footer">
      <submit-button
        class="is-active is-medium is-primary"
        :disabled="canAssignAsset === false"
        @submit="assignAsset"
        :working="isLoading"
      >
        Assign to {{Naming.Asset}}
      </submit-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'

export default {
  data: () => ({
    showAssets: false,
    zoneId: null,
    parentAssetId: null,
    zoneList: [],
    isLoading: false,
  }),

  methods: {
    assignAsset() {
      if (this.canAssignAsset === false) {
        return
      }

      if (this.parentAssetId === this.asset.id) {
        this.$toast.error(`An ${this.Naming.Asset} cannot be transferred to itself, please choose a different parent ${this.Naming.Asset}`)
        return
      }

      this.isLoading = true

      this.$store.dispatch('asset/assignToAsset', {
        asset_id: this.asset.id,
        parent_id: this.parentAssetId
      }).then(() => {
        this.isLoading = false
        this.$toast.success(`${this.Naming.Asset} Assigned!`)
        this.$close(false)
      }).catch((error) => {
        this.isLoading = false
        this.$whoops()
      })
    },
    switchZones() {
      this.zone = this.zoneList.find((zone) => zone.id === this.zoneId)
      this.showAssets = true
    },
    sortAndFilterZones(zones) {
      let filtered = _.filter(zones, zone => {
        let allowedZoneAssetTypeIds = _.map(zone.type.asset_types, 'id')

        if(allowedZoneAssetTypeIds.find((allowedZoneAssetTypeId) => 
          allowedZoneAssetTypeId === this.asset.asset_type_id)){
            return true
          }
      })

      return _.sortBy(filtered, zone => zone.name.toLowerCase())
    },
    sortedFilteredAssets(zone) {
      let filtered = _.filter(zone.assets, asset => 
        asset.id !== this.asset.id && 
        asset.asset_id === null
      )
      
      return _.sortBy(filtered, asset => asset.code.toLowerCase())
    },
  },

  computed: {
    ...mapGetters('asset', [
      'asset',
    ]),
    ...mapGetters('site', [
      'site',
    ]),
    canAssignAsset() {
      return this.zoneId !== null
        && this.parentAssetId !== null
    },
    selectedZone() {
      return find(this.zoneList, { id: this.zoneId })
    },
  },

  watch: {
    zoneId() {
      this.parentAssetId = null
    },
  },

  mounted() {
    this.zoneList = this.sortAndFilterZones(this.site.zones)
    this.zoneId = this.asset.zone.id
    this.showAssets = true
  },
}
</script>
