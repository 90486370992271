import { render, staticRenderFns } from "./PredefinedValuesEditor.vue?vue&type=template&id=0bc71772&"
import script from "./PredefinedValuesEditor.vue?vue&type=script&lang=js&"
export * from "./PredefinedValuesEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports