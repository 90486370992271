<template>
<base-modal wide :title="`${Naming.Inspection} Results`">
  <div class="columns">
    <div class="column">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{ Naming.Check }}</th>
            <th>Date/Time</th>
            <th class="has-text-right">Outcome</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="answer in inspection.answers" :key="answer.id">
            <td>
              {{ answer.check.name }}
              <span v-if="answer.check.instant_fail && answer.answer === 0" class="tag is-danger">instant fail</span>
            </td>
            <td>{{ answer.created_at }}</td>
            <td class="is-narrow">
              <yes-no :value="getOutcome(answer)" yes-text="Passed" no-text="Failed"/>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="has-text-centered" v-if="!inspection.answers.length">No Failed {{ Naming.Checks }}</p>
    </div>
  </div>
  <p class="mt-1 mb-1"><strong>Notes and Additional Comments</strong></p>
  <div v-for="comment in inspection.comments" :key="comment.id" class="is-flex align-center">
    <avatar :path="comment.user.profile.picture_url" :name="comment.user.full_name" class="mr-1 mb-1"/>
    <div class="mb-1">
      <p class="has-text-weight-bold">{{ comment.user.full_name }}</p>
      <p>{{ comment.text }}</p>
    </div>
  </div>
  <div class="is-flex justify-between" slot="footer">
    <action-button
      :working="downloadingInspection"
      class="is-cool-blue"
      @click="downloadInspectionReport(inspection)"
      left-icon="download">
      Download Report
    </action-button>

    <router-link
      class="button is-primary"
      @click.native="$close(false)"
      :to="{
        name: 'inspection-evidence',
        params: {
          inspection: inspection.id,
          site: job.site_id,
          zone: inspection.asset.zone_id,
          asset: inspection.asset_id
        }
      }">
      <span class="has-text-weight-bold">{{ Naming.Inspection }} Overview</span>
      <icon icon="arrow-right"/>
    </router-link>
  </div>
</base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import download from 'downloadjs'
import { job as backend } from '@/api'

export default {

  data: () => ({
    downloadingInspection: false
  }),

  beforeDestroy() {
    this.clear()
  },

  methods: {
    ...mapMutations('inspection', [
      'clear'
    ]),
    getOutcome(answer) {
      return answer.answer !== 0
    },
    downloadInspectionReport(inspection) {
      this.downloadingInspection = true
      backend.downloadInspectionReport({
        job: this.job.id,
        inspection: inspection.id
      }, ({data}) => {
        this.downloadingInspection = false
        download(data, `#${this.job.number}-${this.job.site.name}-inspection-#${inspection.id}.pdf`, 'application/pdf')
      }, error => {
        this.$whoops()
        this.downloadingInspection = false
      })
    },
  },

  computed: {
    ...mapGetters('inspection', [
      'inspection'
    ]),
    ...mapGetters('job', [
      'overview'
    ]),
    job() {
      return this.overview.job
    },
    defectiveAnswers() {
      return this.inspection.answers.filter(answer => answer.answer === 0)
    }
  }

}
</script>
