<template>
  <base-modal title="Password Confirmation">
    <p>
      Please confirm your password to proceed.
    </p>

    <password-input v-model="password">
      Password
    </password-input>

    <div slot="footer" class="buttons">
      <action-button :class="['is-rounded', cancelClass]" @click="$close(false)">{{ cancelText }}</action-button>
      <action-button :class="['has-text-weight-bold is-rounded', confirmClass]" @click="checkPassword">{{ confirmText }}</action-button>
    </div>
  </base-modal>
</template>

<script>
import backend from '@/api/auth'
export default {
  props: {
    title: String,
    message: String,
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmClass: {
      type: String|Object,
      default: 'is-success'
    },
    cancelClass: {
      type: String|Object,
      default: 'is-light'
    }
  },

  data: () => ({
    confirming: false,
    password: ''
  }),

  methods: {
    checkPassword() {
      this.confirming = true
      backend.confirmPassword({
        password: this.password
      }, () => {
        this.confirming = false
        this.$toast.success('Password confirmed')
        this.$close(true)
      }, error => {
        this.confirming = false
        this.$toast.error('Password does not match')
      })
    }
  }
}
</script>
