<template>
<base-modal title="Upload Document">
  <form>
    <div class="columns">
      <div class="column">
        <text-input 
          description="A descriptive title for the document"
          required 
          :value="file.title" 
          @input="title" 
          :error="$root.errors.title">
          Title
        </text-input>
      </div>
      <div class="column">
        <date-picker 
          description="If left blank, the document will not expire"
          :error="$root.errors.expires_at"
          :value="file.expires_at"
          @input="expiryDate">
          Expiry Date
        </date-picker>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <text-input 
          :error="$root.errors.description"
          :value="file.description"
          @input="description"
          required>
          Description
        </text-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <file-input 
          description="Max file size: 50Mb"
          required
          :error="$root.errors.file" 
          @select="setFile">
          Document
        </file-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button :working="uploading" @submit="uploadDocument">Upload</submit-button>
      </div>
    </div>
  </form>
</base-modal>  
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  props: {
    uploadPath: {
      type: String,
      required: true
    }
  },

  data: () => ({
    uploading: false
  }),

  beforeDestroy() {
    this.$store.commit('documents/clear')
  },

  methods: {
    ...mapMutations('documents', [
      'title',
      'description',
      'setFile',
      'expiryDate'
    ]),
    uploadDocument() {
      this.uploading = true
      this.$store.dispatch('documents/uploadDocument', this.uploadPath).then(() => {
        this.uploading = false
        this.$toast.success('Document successfully uploaded')
        this.$close(true)
      }).catch(error => {
        this.$whoops()
        this.uploading = false
      })
    }
  },

  computed: {
    ...mapGetters('documents', [
      'file'
    ]),
  }

}
</script>