<template>
<base-modal :title="`Edit ${Naming.AssetType}`">
  <form>
    <div class="columns">
      <div class="column">
        <text-input 
          required
          placeholder="Vehicle"
          :error="$root.errors.name" 
          :value="assetType.name" 
          @input="name">
          Name
        </text-input>
      </div>
      <div class="column is-narrow">
        <text-input 
          :error="$root.errors.short_code" 
          :value="assetType.short_code" 
          @input="code">
          Short Code
        </text-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <data-selector
          multiple
          delete-buttons
          required
          searchable
          :description="`Select one or more ${Naming.Checklists.toLowerCase()} for this ${Naming.AssetType.toLowerCase()}.`"
          :error="$root.errors.checklists"
          :items="checklistList"
          value-key="id"
          label-key="name"
          :value="assetType.checklists"
          @input="setChecklists">{{ Naming.Checklists }}</data-selector>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <select-input
          :description="`Indicate the level of risk, that ${Naming.Assets.toLowerCase()} of this type will carry.`"
          :error="$root.errors.risk_id"
          :items="riskLevelList"
          value-key="id"
          label-key="level"
          :value="assetType.risk_id"
          @input="risk">
          Risk Level
        </select-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button :working="working" @submit="save" class="is-success" left-icon="check">Save</submit-button>
      </div>
      <div class="column has-text-right">
        <action-button :disabled="assetType.assets_count > 0" :working="deleting" @click="deleteType" class="is-danger" left-icon="trash">Delete</action-button>
      </div>
    </div>
  </form>
</base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Vue from "vue";

export default {

  props: {
    type: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    working: false,
    deleting: false
  }),

  created() {
    console.log(Vue.prototype.NamingEnum)
    this.setAssetType(this.type)
    this.$store.dispatch('checklist/loadChecklistList')
    this.$store.dispatch('riskLevel/loadRiskLevelList')
  },

  beforeDestroy() {
    this.clearType()
  },

  methods: {
    ...mapMutations('assetType', [
      'name',
      'setChecklists',
      'code',
      'setAssetType',
      'clearType',
      'risk'
    ]),
    save() {

      this.working = true;
      this.$store.dispatch('assetType/save').then(() => {
        this.working = false;
        this.$toast.success(this.Convert(this.$lang.assetType.saved))
      }).catch(() => {
        this.working = false
        this.$whoops()
      });
    },
    async deleteType() {
      if (await this.$confirm({
        ...this.$lang.assetType.confirmDelete,
        confirmClass: 'is-danger'
      })) {
        this.$store.dispatch('assetType/delete').then(() => {
          this.working = false
          this.$toast.success(this.Convert(this.$lang.assetType.deleted))
          this.$close(true)
        }).catch(() => {
          this.working = false
          this.$whoops()
        })
      }
    }
  },

  computed: {
    ...mapGetters('assetType', ['assetType']),
    ...mapGetters('checklist', ['checklistList']),
    ...mapGetters('riskLevel', ['riskLevelList' ])
  }
}
</script>
