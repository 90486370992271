<template>
  <base-modal form wide title="Open a New Ticket">

    <text-input
      autofocus
      required
      :label="false"
      placeholder="Enter a short title"
      :value="ticket.title"
      :error="$root.errors.title"
      classes="is-large has-text-weight-bold"
      @input="setTitle"/>

    <div class="grid has-3-columns gap-1">
      <data-selector
        :items="ticketTypes"
        :value="ticket.type"
        :error="$root.errors.type"
        @input="setType">
        Ticket Type
      </data-selector>

      <data-selector
        type="inline"
        :items="ticketPriorities"
        :value="ticket.priority"
        :error="$root.errors.priority"
        @input="setPriority">
        Priority
      </data-selector>
    </div>

    <text-area
      required
      :value="ticket.body"
      :error="$root.errors.body"
      @input="setBody"
      class="is-2-columns"
      classes="resize-none"
      rows="8"
      :max-rows="8"
      :placeholder="bodyPlaceholder"
      :max="2000">
      {{ bodyTitle }}
    </text-area>

    <file-input
      description="Supported file types: .png, .jpeg, .jpg, .pdf, .docx, .xlsx, or .txt (max 10MB)"
      :error="$root.errors.file"
      @select="setFile">
      Optional Attachment
    </file-input>
    <p class="has-text-grey">You can add more attachments once the ticket has been opened.</p>

    <div class="buttons" slot="footer">
      <submit-button
        v-bind="{ working }"
        :disabled="ticket.type === 1"
        @click="openTicket"
        class="is-rounded is-success"
        left-icon="plus">
        Open Ticket
      </submit-button>
    </div>

  </base-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
  }),

  computed: {
    ...mapState('support', {
      ticket: 'newTicket'
    }),

    ...mapGetters('list', [
      'ticketPriorities',
      'ticketTypes',
    ]),

    bodyPlaceholder() {
      return this.ticket.type ? {
        1: 'Please tell us about the new feature you would like to see added to the system, and provide reasons where applicable. Once received, we will review your request and if it is doable, we’ll roadmap it and let you know. If not, we’ll provide applicable explanations.',
        2: 'Please provide all the applicable details relating to your issue:\n\n  • If your issue relates to one or more jobs, please provide the job numbers.\n  • If your issue relates to specific inspection(s), please provide the inspection numbers.\n  • If your issue relates to specific one or more assets, please provide the asset identifier or barcode.\n  • If your issue related to a specific platform (such as the mobile app), please let us know which users were impacted.\n  • Please be clear and precise when conveying your issue to us.',
        3: 'Please provide all applicable details. If we deem this request to be a support request, we’ll update the ticket and respond accordingly.'
      }[this.ticket.type] : false;
    },

    bodyTitle() {
      return this.ticket.type ? {
        1: 'Details of Feature Request',
        2: 'Issue Details',
        3: 'Request Details',
      }[this.ticket.type] : 'Details'
    }
  },

  beforeDestroy() {
    this.clearNewTicket()
  },

  methods: {
    ...mapMutations('support', [
      'clearNewTicket',
      'setTitle',
      'setBody',
      'setPriority',
      'setType',
      'setFile'
    ]),

    openTicket() {
      this.working = true
      this.$store.dispatch('support/openTicket')
        .then(this.$close)
        .finally(() => this.working = false)
    }
  }

}
</script>
