<template>
<base-modal title="Barcode Batch Request">
  <form>
    <div class="columns">
      <div class="column">
        <text-input 
          classes="is-medium"
          required
          :error="$root.errors.reference" 
          v-model="generator.reference">
          Reference
        </text-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <number-input 
          classes="is-medium"
          required 
          :precision="0"
          :min="50" 
          :max="1000"
          :error="$root.errors.count" 
          v-model="generator.count">
          Request Between 50 and 1000 QR Codes at a time.
        </number-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button 
          :working="working"
          @submit="requestCodes"
          class="is-success is-medium"
          left-icon="sync-alt">
          Request Codes
        </submit-button>
      </div>
    </div>
  </form>
</base-modal>  
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    generator: {
      count: 0,
      reference: ''
    }
  }),

  methods: {
    requestCodes() {
      this.working = true
      this.$store.dispatch('barcode/requestCodes', this.generator).then(() => {
        this.$toast.success('Thank you we have received your request')
        this.$close(true)
        this.working = false
      }).catch(error => {
        this.working = false
      })
    }
  }
}
</script>
