<template>
  <base-modal form title="Attach File to Support Ticket" footer-class="is-flex justify-end">

    <file-input
      description="Supported file types: .png, .jpeg, .jpg, .pdf, .docx, .xlsx, or .txt (max 10MB)"
      :error="$root.errors.file"
      @select="setNewFile">
      Attachment
    </file-input>

    <div class="buttons" slot="footer">
      <submit-button
        v-bind="{ working }"
        @click="attachFile"
        class="is-success is-rounded"
        left-icon="plus">
        Attach File
      </submit-button>
    </div>

  </base-modal>
</template>

<script>
import { mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
  }),

  methods: {
    ...mapMutations('support', ['setNewFile']),

    attachFile() {
      this.working = true
      this.$store.dispatch('support/attachFile')
        .then(this.$close)
        .finally(() => this.working = false)
    }
  }

}
</script>
