<template>
<base-modal :title="`Recover Decommissioned ${Naming.Asset}`">

  <div class="columns">
    <div class="column">
      <message class="is-danger" v-if="!asset.barcode || !asset.barcode.id">
        This {{ Naming.Asset.toLowerCase() }} does not have a barcode associated with it. While recovery is possible, this
        {{ Naming.Asset.toLowerCase() }} will not be scannable in the field.
      </message>

      <p>
        <strong>{{ Naming.Asset }} Internal ID:</strong> {{ asset.code }}
      </p>
      <p>
        <strong>Barcode:</strong> {{ asset.barcode.data || 'No Barcode Found' }}
      </p>
    </div>
  </div>

  <template #footer>
    <div class="buttons">
      <action-button
        :working="recovering" 
        @click="recoverDecommissionAsset">
        Recover {{ Naming.Asset }}
      </action-button>
    </div>
  </template>
</base-modal>
</template>
<script>
export default {
  
  props: {
    asset: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    recovering: false
  }),

  methods: {
    recoverDecommissionAsset() {
      this.recovering = true
      this.$store.dispatch('asset/recoverDecommissionedAsset', this.asset.id).then(() => {
        this.$close(true)
        this.recovering = true
        this.$toast.success('Asset Recovered')
      }).catch(error => {
        this.recovering = false
      })
    },
    
  }

}
</script>