<template>
  <base-modal title="Decommission Asset">
    <div v-if="hasSubassets"  class="columns">
      <div class="column">
        <message class="is-warning">This {{ Naming.Asset }} has component {{ Naming.Assets }} attached to it. If you decommission this
        asset, all asset components will be detached from the parent asset.</message>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <text-area :error="$root.errors.reason" v-model="reason"
          >Please provide a reason for decommissioning this asset.
        </text-area>
      </div>
    </div>

    <div slot="footer">
      <submit-button :working="working" @submit="decommissionAsset" class="is-danger">Decommission</submit-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {

  data: () => ({
    reason: '',
    sub_assets_handle_method: 'decouple',
    working: false,
  }),

  created() {
    this.$store.dispatch("asset/loadAsset", this.$route.params.asset);
  },
  methods: {
    decommissionAsset() {
      this.working = true;
      this.$store
        .dispatch("asset/decommission", {
          reason: this.reason,
          sub_assets_handle_method: this.sub_assets_handle_method,
        })
        .then(() => {
          this.working = false;
          this.$toast.success("Asset decommissioned.");
          this.$close(true);
        })
        .catch((error) => {
          this.working = false;
        });
    },
  },

  computed: {
    ...mapGetters("asset", ["asset"]),
    hasSubassets() {
      if (this.asset.sub_assets.length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>
