<template>
  <base-modal form :title="`${Naming.Inspection} Summary Report Options`">
    <div class="grid has-2-columns gap-1">
      <p>Please select from the options below.</p>
    </div>
    <div class="grid has-3-columns gap-1 mt-1">
      <switch-input
          classes="is-medium"
          @input="toggleShowMap"
          :value="options.show_map"
      >
        Include Map Image
      </switch-input>
      <switch-input
          classes="is-medium"
          @input="toggleShowPhotos"
          :value="options.show_photos"
      >
        Include Photo Evidence
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleShowInspectionComments"
          :value="options.show_comments"
      >
        Show Closing Comments
      </switch-input>
    </div>

    <div class="grid has-3-columns gap-1">
      <switch-input
          classes="is-medium"
          @input="toggleAssetCustomFields"
          :value="options.show_custom_fields"
      >
        Include Asset Custom Fields
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleShowAnswerComments"
          :value="options.show_answer_comments"
      >
        Include Answer Comments
      </switch-input>

      <switch-input
          classes="is-medium"
          @input="toggleShowAddressDetails"
          :value="options.show_address_details"
      >
        Show Addresses
      </switch-input>

    </div>

    <footer class="buttons is-right mt-2">

      <action-button
          @click="downloadInspectionSummaryReport"
          :working="working"
          left-icon="download"
          class="is-rounded is-success">
        Download
      </action-button>
    </footer>
  </base-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {inspection as backend} from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    working: false,
    options: {
      show_map: false,
      show_photos: false,
      show_comments: false,
      show_custom_fields: false,
      show_answer_comments: false,
      show_address_details: false,
    },
  }),

  methods: {

    toggleShowMap() {
      this.options.show_map = !this.options.show_map
    },

    toggleShowPhotos() {
      this.options.show_photos = !this.options.show_photos
    },

    toggleShowInspectionComments() {
      this.options.show_comments = !this.options.show_comments
    },

    toggleAssetCustomFields() {
      this.options.show_custom_fields = !this.options.show_custom_fields
    },

    toggleShowAnswerComments() {
      this.options.show_answer_comments = !this.options.show_answer_comments
    },

    toggleShowAddressDetails() {
      this.options.show_address_details = !this.options.show_address_details
    },

    downloadInspectionSummaryReport() {
      const payload = {
        inspectionId: this.$store.getters['inspection/inspection'].id,
        options: this.options
      }

      this.working = true

      backend.downloadInspectionSummaryReport(payload, ({data}) => {
        download(data, `inspection-summary-${payload.inspectionId}-report.pdf`, 'application/pdf')
        this.$close(false)
      }, error => {
        this.$whoops()
      }).finally(() => this.working = false)
    },
  },

  computed: {
    ...mapGetters('job', [
      'job'
    ]),
    ...mapGetters('inspection', [
      'inspections'
    ]),

    assetTypes() {
      return _.uniqBy(this.inspections.data.map((inspection) => {
        return {id: inspection.asset?.type?.id, name: inspection.asset?.type?.name}
      }), (assetType) => assetType.id)
    },

    showSelectAllAssetTypes() {
      return _.uniqBy(this.inspections.data.map((inspection) => {
        return {id: inspection.asset?.type?.id, name: inspection.asset?.type?.name}
      }), (assetType) => assetType.id).length > 4
    },

    jobPassFailOrPercentage() {
      return this.options.show_pass_or_percentage ? "Show Job Pass/Fail" : "Show Job Percentage"
    },
  },
}
</script>
