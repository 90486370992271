<template>
<base-modal title="Comment Change History">
  <div class="columns">
    <div class="column">
      <p class="is-flex justify-between">
        <strong>Answer</strong>
        <span v-if="answer.answer === 1" class="is-flex justify-between align-center">
          <icon class="has-text-success" icon="check-circle lg"/>
          <span>Passed</span>
        </span>
        <span v-if="answer.answer === 0" class="is-flex justify-between align-center">
          <icon class="has-text-danger" icon="times-circle lg"/>
          <span>Failed</span>
        </span>
        <span v-if="answer.answer === -1" class="is-flex justify-between align-center">
          <icon class="has-text-warning" icon="dot-circle lg"/>
          <span>N/A</span>
        </span>
      </p>
    </div>
    <div class="column">
      <p class="is-flex justify-between">
        <strong>{{ Naming.Check }}</strong> {{ answer.check.name }}
      </p>
    </div>
  </div>

  <div class="columns" v-for="change in updates" :key="change.id">
    <div class="column">
      <div>
        <p>
          <strong>Changed</strong> {{ change.created_at | date('L LTS') }}
        </p>
        
        <div class="box">
          <p>
            <strong>Old Value</strong>
          </p>
          <p class="has-text-grey">{{ change.old_values.text }}</p>
          <p>
            <strong>Change made by {{ change.user.full_name }}</strong>
          </p>
          <p class="has-text-grey">{{ change.new_values.text }}</p>
        </div>
      </div>
    </div>
  </div>
</base-modal>  
</template>
<script>
export default {
  props: {
    answer: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    updates() {
      return this.answer.comment.audits.filter(audit => audit.event === 'updated')
    }
  }
}
</script>