<template>
  <base-modal wide form :title="`New ${Naming.Check}`">
    <div class="columns is-multiline">
      <div class="column is-half">
        <text-input
            required
            :error="$root.errors.name"
            :value="check.name"
            @input="name"
            :description="`Give this ${Naming.Check.toLowerCase()} a short, descriptive label.`"
        >Name
        </text-input>
        <p v-if="!hasValidNameLength" class="has-text-danger is-danger">A valid Name must be provided. Names have a maximum length of 255 characters.</p>
      </div>
      <div class="column is-half">
        <switch-input
            :description="`If this ${Naming.Check.toLowerCase()} fails, the ${Naming.Inspection.toLowerCase()} will fail, putting the ${Naming.Asset.toLowerCase()} into a state of risk.`"
            classes="is-medium"
            @input="toggleInstantFail"
            :value="check.instant_fail">
          Instant Fail
        </switch-input>
      </div>
      <div class="column is-half">
        <data-selector
          :error="$root.errors.image_rule"
          :value="check.image_rule"
          @input="imageRule"
          :items="checkRules"
          :description="`Adjust the ruleset governing photographic evidence for this ${Naming.Check.toLowerCase()}.`">
          Image Rule
        </data-selector>
      </div>
      <div class="column is-half">
        <switch-input
            :description="`This will allow the ${Naming.Check.toLowerCase()} to be skipped by selecting the N/A button in the app.`"
            classes="is-medium"
            @input="toggleCanSkip"
            :value="check.can_skip">
          Can Skip
        </switch-input>
      </div>
      <div class="column is-half">
        <div>
          <h2 class="label is-marginless">
            {{ Naming.Check }} Image
          </h2>
          <div class="card" v-if="imagePreview">
            <small class="has-text-grey mb-1">The maximum image size is 5 MB.</small>
            <div class="card-content p-0 pt-1">
              <div class="image" style="!important; max-width: 60%;">
                <figure class="image is-6by5">
                  <img :src=imagePreview alt="Check Image">
                </figure>
              </div>
            </div>
          </div>
          <small class="has-text-grey" v-else>Upload an image of the {{ Naming.Asset.toLowerCase() }}. The maximum image size is 5 MB.</small>
          <div class="is-flex justify-between mt-1">
            <input type="file"
                   :disabled="false"
                   @change="filesChange"
                   accept="image/*"
                   class="is-primary is-small is-rounded">
            <action-button
                v-if="check.image"
                @click="removeImage"
                left-icon="trash"
                class="is-danger is-small is-rounded"
                :working="false">
              Remove Image
            </action-button>
          </div>
        </div>
      </div>
      <div class="column is-half">
        <data-selector
          :error="$root.errors.comment_rule"
          :value="check.comment_rule"
          @input="commentRule"
          :items="checkRules"
          :description="`Adjust the ruleset governing comments for this ${Naming.Check.toLowerCase()}.`">
          Comment Rule
        </data-selector>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half"></div>
      <div class="column is-half">
        <text-area
            required
            description="Provide an accurate description of the task to be performed."
            :error="$root.errors.task"
            :value="check.task"
            @input="task">
          Task Description
        </text-area>
        <p class="help-danger" v-if="$root.errors.delete">{{ $root.errors.delete[0] }}</p>
        <p v-if="!hasValidTaskLength" class="has-text-danger is-danger">A valid Task must be provided. Tasks have a maximum length of 750 characters.</p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <predefined-comments-table
          v-if="$root.hasAbility('manage-predefined-comments', 'Billow\\Models\\Checklist\\Check')"
          @error="handleError"/>
      </div>
    </div>
    <div slot="footer" class="buttons">
      <submit-button
        :disabled="!canCreateCheck"
        :working="working"
        @submit="store"
        class="is-success is-inverted"
        left-icon="check">
        Create {{ Naming.Check }}
      </submit-button>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

const MAX_NAME_LENGTH = 255
const MAX_TASK_LENGTH = 750
export default {

  data: () => ({
    working: false,
    imagePreview: null,
    error: false,
  }),

  created() {
    this.clear()
  },

  beforeDestroy() {
    this.clearCheck()
  },

  methods: {
    ...mapMutations('check', [
      'name',
      'task',
      'clearCheck',
      'imageRule',
      'commentRule',
      'image',
    ]),
    ...mapMutations('checkPredefinedComments', [
      'setCheck',
      'updateAllCheckPredefinedComments',
      'clear'
    ]),
    
    removeImage() {
      delete this.check.image
      this.imagePreview = null
    },
    filesChange(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.check.image = event.target.files[0]
      this.imagePreview = URL.createObjectURL(this.check.image)
    },
    async store() {
      this.working = true
      if (this.imagePreview === null) {
        delete this.check.image;
      }
      // we need to wait for the check to be created before we can create predefined comments
      try {
        let check = await this.$store.dispatch('check/store')
        this.setCheck(check)
        this.updateAllCheckPredefinedComments(check)
        await this.$store.dispatch('checkPredefinedComments/store')
        this.$toast.success(this.Convert(this.$lang.check.created))
        this.working = false
        this.$close(check)
      } catch (error) {
        this.working = false
        this.$whoops()
      }
    },
    toggleInstantFail(){
      this.check.instant_fail = !this.check.instant_fail
    },

    toggleCanSkip(){
      this.check.can_skip = !this.check.can_skip
    },
    /**
     * 
     * @param {string} message 
     */
    handleError(message) {
      if (message) {
        this.error = true
      } else {
        this.error = false
      }
      
    }
  },

  computed: {
    ...mapGetters('check', [
      'check',
    ]),
    ...mapGetters('list', [
      'checkRules',
    ]),
    canCreateCheck() {
      return this.hasValidNameLength && this.hasValidTaskLength
    },
    hasValidNameLength() {
      return this.check.name.length <= MAX_NAME_LENGTH
    },
    hasValidTaskLength() {
      return this.check.task.length <= MAX_TASK_LENGTH
    },
  },
}
</script>
