<template>
  <base-modal :title="`Edit ${Naming.AssetGroup}`">
    <form>
      <div class="columns">
        <div class="column">
          <text-input
            required
            placeholder="Repair, Survey..."
            :error="$root.errors.description"
            :value="assetGroup.description"
            @input="description">Description
          </text-input>
        </div>
        <div class="column">
          <select-input :error="$root.errors.risk_id" :value="assetGroup.risk_id" @input="risk" :items="riskLevelList" value-key="id" label-key="level">
            Risk
          </select-input>
        </div>
      </div>
      <data-selector
        v-if="loaded"
        searchable
        multiple
        tag-class="is-info"
        delete-buttons
        :value="selectedAssetTypes"
        @input="zoneAssetTypes"
        :error="$root.errors.asset_types"
        :items="assetTypeList"
        label-key="name"
        value-key="id">
        {{ Naming.AssetTypes }} allowed in the {{ Naming.Zone }}
      </data-selector>
      <div class="columns">
        <div class="column">
          <submit-button :disabled="working" @submit="save" class="is-success" left-icon="check">Save</submit-button>
        </div>
        <div class="column is-narrow">
          <action-button v-if="!assetGroup.zones_count" :disabled="working" @click="deleteType" class="is-danger" left-icon="trash">Delete</action-button>
          <p class="has-text-danger has-text-right is-size-7" v-else>There are existing {{ Naming.Zones }} tied to this
            {{ Naming.AssetGroup }}.<br> As such, it cannot be deleted.</p>
        </div>
      </div>
    </form>
  </base-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { map } from 'lodash'

export default {

  props: {
    type: {
      type: Object,
      default: () => { }
    }
  },

  data: () => ({
    working: false,
    loaded: false,
    selectedAssetTypes: []
  }),

  created() {
    Promise.all([
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
      this.$store.dispatch('assetType/loadAssetTypeList')
    ]).then(() => this.loaded = true)
  },

  mounted() {
    this.$store.commit('assetGroup/setAssetGroup', this.type)
    this.selectedAssetTypes = this.assetGroup.asset_types
  },

  beforeDestroy() {
    this.clearType()
  },

  methods: {
    ...mapMutations('assetGroup', [
      'description',
      'risk',
      'clearType',
      'zoneAssetTypes'
    ]),
    save() {
      this.working = true
      this.$store.dispatch('assetGroup/save').then(() => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.assetGroup.saved))
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    async deleteType() {
      const { title, message, confirmText } = this.$lang.assetGroup.confirmDelete;
      const convertedTitle = await this.Convert(title);
      const convertedMessage = await this.Convert(message);
      const convertedConfirmText = await this.Convert(confirmText)
      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger'
      })) {
        this.working = true;
        this.$store.dispatch('assetGroup/deleteAssetGroup').then(() => {
          this.working = false;
          this.$toast.success(this.Convert(this.$lang.assetGroup.deleted));
          this.$close(true);
        }).catch(error => {
          this.working = false;
          console.error(error);
          this.$whoops();
        });
      }
    },
  },

  computed: {
    ...mapGetters('assetGroup', [
      'assetGroup',
      'assetGroups',
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ])
  }
}
</script>
