<template>
  <base-modal title="Edit Sensor Name">
    <div class="columns">
      <div class="column">
        <form>
          <text-input
              classes="is-rounded"
              v-model="personalName"
              placeholder="Give this Sensor a name..."
              :label="false">
          </text-input>
        </form>
      </div>
    </div>
    <template #footer>
      <action-button
          :disabled="!canAssignSensor"
          :working="working"
          @click="processAssignment"
          class="is-primary is-rounded">
        Save
      </action-button>
    </template>
  </base-modal>
</template>

<script>
import Sensor from '@/models/Sensor'

export default {
  props: {
    sensor: {
      type: Object,
      default: () => new Sensor()
    },
  },

  data: () => ({
    working: false,
    personalName: ''
  }),

  methods: {
    processAssignment() {
      this.working = true
      this.$store.dispatch('sensor/updateSensorPersonalName', {
        sensorId: this.sensor.id,
        personalName: this.personalName
      }).then(() => {
        this.working = false
        this.$close(true)
        this.$toast.success('Sensor name successfully updated.')
      }).catch(error => {
        this.working = false
        this.$close(true)
        this.$toast.error(`Could not update the sensor's name. Please try again.`)
      })
    }
  },

  computed: {
    canAssignSensor() {
      return this.personalName.length > 0
    }
  },

  mounted() {
    this.personalName = this.sensor.personal_name
  }
}
</script>